import Vue from "vue";
import moment from "moment";
import secureAxios from "../services/axiosInstance";
import store from "../store";

Vue.prototype.moment = moment;
Vue.prototype.$http = secureAxios;

Vue.prototype.moneyFormatter = (value) => {
  if (isNaN(Number(value))) {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
  });
  return formatter.format(Number(value));
};

Vue.filter("moment", function (value, format) {
  if (value === null || value === undefined || format === undefined) {
    return value;
  }
  if (format === "from") {
    return moment(value).fromNow();
  }

  const val = moment(value).format(format);
  return val === "Invalid date" ? value : val;
});

Vue.filter("toCurrency", (value) => {
  if (isNaN(Number(value))) {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
  });
  return `₦ ${formatter.format(Number(value))}`;
});

Vue.directive("uppercase", (el) => {
  el.value = el.value ? el.value.toUpperCase() : el.value;
});

Vue.directive("can", (el, binding, vnode) => {
  if (binding.value && !store.getters["admin/permit"](binding.value)) {
    vnode.elm.style.display = "none";
  }
});

/* App configs */
export default {
  APP_NAME: process.env.VUE_APP_HEADER_NAME,
  PROJECT_NAME: process.env.VUE_APP_PROJECT_NAME,
  API_URL: process.env.VUE_APP_API_URL,
  CONTACT_PHONE: process.env.VUE_APP_CONTACT_PHONE,
};
