<template>
  <div class="">
    <filter-bar v-if="showSearch"></filter-bar>
    <vuetable
      class="mt-4"
      ref="vuetable"
      :api-mode="false"
      :data="tableData"
      :fields="fields"
      pagination-path=""
      :data-path="dataPath"
      :per-page="10"
      :multi-sort="true"
      :sort-order="sortOrder"
      :append-params="appendParams"
      :query-params="queryParams"
      :detail-row-component="detailRowComponent"
      @vuetable:pagination-data="onPaginationData"
      :track-by="trackBy"
      :row-class="onRowClass"
      :css="css.table"
    >
      <template slot="id" slot-scope="props">
        <div class="cursor-pointer">
          <span>{{ pageIndex + (props.rowIndex + 1) }} </span>
        </div>
      </template>
      <!-- @vuetable:cell-clicked="onCellClicked" -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </vuetable>
    <div v-if="showPage" class="flex justify-between mt-3">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueEvents from "vue-events";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import FilterBar from "./FilterBar";
import TableStyle from "../../utils/TableStyle";

Vue.use(VueEvents);
Vue.component("filter-bar", FilterBar);

export default {
  name: "MyTable",
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  data() {
    return {
      css: TableStyle,
      pageIndex: 0,
    };
  },
  props: {
    bus: {
      type: [Object, String],
    },
    apiUrl: {
      type: String,
      // required: true,
    },
    fields: {
      type: Array,
      // required: true,
    },
    sortOrder: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    appendParams: {
      type: Object,
      default() {
        return {};
      },
    },
    queryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    detailRowComponent: {
      type: [String],
    },
    dataPath: {
      type: String,
    },
    trackBy: {
      type: String,
      default: "_id",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showPage: {
      type: Boolean,
      default: true,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    // eslint-disable-next-line no-unused-vars
    this.$events.$on("filter-reset", (e) => this.onFilterReset());
  },

  created() {
    if (this.bus) {
      this.bus.$on("cellClicked", (data) => {
        this.onCellClicked(data);
      });
    }
  },

  methods: {
    onRowClass(dataItem, index) {
      let cls = "hover:bg-gray-100 ";
      cls += index % 2 ? "bg-gray-100" : "bg-white";
      return cls;
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onCellClicked(data) {
      this.$refs.vuetable.toggleDetailRow(data.id);
    },
    onFilterSet(filterText) {
      this.appendParams.filter = filterText;
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      delete this.appendParams.filter;
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
  },
};
</script>
