import { render, staticRenderFns } from "./ApiTable.vue?vue&type=template&id=7f519bc1&scoped=true&"
import script from "./ApiTable.vue?vue&type=script&lang=js&"
export * from "./ApiTable.vue?vue&type=script&lang=js&"
import style0 from "./ApiTable.vue?vue&type=style&index=0&id=7f519bc1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f519bc1",
  null
  
)

export default component.exports