import sa from "./axiosInstance";

export const fetchTowns = async () => {
  const res = await sa.get("/address/towns", {
    headers: { noauth: true },
  });
  return res ? res.data.data.data : [];
};
export const fetchAreas = async (town) => {
  const res = await sa.get("/address/areas?search=" + town, {
    headers: { noauth: true },
  });
  return res ? res.data.data.data : [];
};
export const fetchStreets = async (area) => {
  const res = await sa.get("/address/streets?limit=300&search=" + area, {
    headers: { noauth: true },
  });
  return res ? res.data.data.data : [];
};
export const fetchPermissions = async () => {
  const res = await sa.get("/permissions?limit=300", {
    // headers: { noauth: true },
  });
  return res ? res.data.data.data : [];
};
