import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import admin from "./admin";
import general from "./general";
import agent from "./agent";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  key: process.env.VUE_APP_appDB,
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    admin,
    agent,
    general,
  },
  plugins: [vuexLocal.plugin],
});
