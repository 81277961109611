import axios from "axios";
import Swal from "sweetalert2";

const secureAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

secureAxios.interceptors.request.use((config) => {
  if (!config.headers.noauth) {
    const token = localStorage.getItem(process.env.VUE_APP_tokenName);

    if (!token) {
      window.location.href = "/";
      return config;
    }

    config.headers["x-access-token"] = token;
  }

  return config;
});

secureAxios.interceptors.response.use(
  (response) => {
    // console.log(response);
    return response;
  },
  (err) => {
    console.log({ err });

    if (
      !err.response ||
      err.response.status == 500 ||
      err.response.status == 502
    ) {
      Swal.fire({
        text: "Something went wrong, contact admin",
        icon: "info",
        confirmButtonColor: "#2FB053",
        cancelButtonColor: "#333231",
      });

      return false;
    }

    // if token is expired, return to the home page
    if (err.response.status == 401 && err.response.data.errorCode == "N403") {
      window.location.href = "/";
      return false;
    }

    const error_message = err.response.data.message
      ? err.response.data.message
      : err.response.data.errors.message;

    Swal.fire({
      title: "Error!",
      text: error_message,
      icon: "error",
      allowOutsideClick: false,
      confirmButtonColor: "#2FB053",
      cancelButtonColor: "#333231",
    });

    return false;
  }
);

export default secureAxios;
