const pageBtn =
  "px-3 py-1 flex items-center bg-ansLemon rounded-lg cursor-pointer";
export default {
  table: {
    tableWrapper: "",
    tableHeaderClass: "bg-gray-200 text-sm font-semibold",
    tableBodyClass: "mb-0",
    tableClass: "w-full",
    loadingClass:
      "inline-block w-6 h-6 rounded-full animate-spin border-4 border-solid border-ansGreen border-t-transparent",
    ascendingIcon: "fa fa-chevron-up",
    descendingIcon: "fa fa-chevron-down",
    ascendingClass: "sorted-asc",
    descendingClass: "sorted-desc",
    sortableIcon: "fa fa-sort",
    detailRowClass: "vuetable-detail-row",
    handleIcon: "fa fa-bars text-secondary",
    // eslint-disable-next-line no-unused-vars
    renderIcon(classes, options) {
      return `<i class="${classes.join(" ")}"></span>`;
    },
  },
  pagination: {
    wrapperClass: "flex text-sm text-ansBlack gap-1",
    activeClass: "font-bold border text-white bg-ansBlack",
    disabledClass: "opacity-50",
    pageClass: pageBtn,
    linkClass: pageBtn,
    paginationClass: "pagination",
    paginationInfoClass: "float-left",
    dropdownClass: "form-control",
    icons: {
      first: "fa fa-arrow-left",
      prev: "fa fa-chevron-left",
      next: "fa fa-chevron-right",
      last: "fa fa-arrow-right",
    },
  },
};
