export default [
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/AppFrame.vue"),
    children: [
      {
        path: "dashboard",
        name: "Admin Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Dashboard.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "agents",
        name: "Admin Agents",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Agents.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "agents/:id",
        name: "Admin Agent",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Agent.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "taxpayers",
        name: "Admin TaxPayers",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/TaxPayers.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "taxpayers/:id",
        name: "Admin TaxPayer",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/TaxPayer.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "admins",
        name: "Admin Admins",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Admins.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "admins/:id",
        name: "Admin Admin",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Admin.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "revenues",
        name: "Admin Revenues",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Revenues.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "revenue-heads",
        name: "Admin Revenue Head",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/RevenueHeads.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "revenue-sub-heads",
        name: "Admin Revenue SubHead",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/RevenueSubHeads.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "institutions",
        name: "Admin Institutions",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Institutions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "subscriptions",
        name: "Admin Subscriptions",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Subscriptions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "invoices",
        name: "Admin Invoices",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Invoices.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "transactions",
        name: "Admin Transactions",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Transactions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "receipts",
        name: "Admin Receipts",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Receipts.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "roles",
        name: "Admin Roles",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Roles.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "roles/:id",
        name: "Admin Role",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Role.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "edit-role/:id",
        name: "Admin Edit Role",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/EditRole.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "permissions",
        name: "Admin Permissions",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Permissions.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
];
