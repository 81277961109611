export default [
  {
    path: "/taxpayer/login",
    name: "TaxPayer Login",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/taxpayer/Login.vue"),
  },
  {
    path: "/taxpayer/forgot-password",
    name: "TaxPayer Forgot Password",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/taxpayer/ForgotPassword.vue"
      ),
  },
  {
    path: "/taxpayer/reset-password",
    name: "TaxPayer Reset Password",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/taxpayer/ResetPassword.vue"
      ),
  },
  {
    path: "/taxpayer",
    component: () =>
      import(
        /* webpackChunkName: "taxpayer" */ "../views/taxpayer/AppFrame.vue"
      ),
    children: [
      {
        path: "dashboard",
        name: "TaxPayer Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "taxpayer" */ "../views/taxpayer/Dashboard.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "invoices",
        name: "TaxPayer Invoices",
        component: () =>
          import(
            /* webpackChunkName: "taxpayer" */ "../views/taxpayer/Invoices.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "transactions",
        name: "TaxPayer Transactions",
        component: () =>
          import(
            /* webpackChunkName: "taxpayer" */ "../views/taxpayer/Transactions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "receipts",
        name: "TaxPayer Receipts",
        component: () =>
          import(
            /* webpackChunkName: "taxpayer" */ "../views/taxpayer/Receipts.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
];
