<template>
  <input :value="computedValue" v-bind="attributes" ref="input" />
</template>

<script>
export default {
  name: "MoneyInput",
  props: {
    value: {
      type: [Number, String],
    },
    placeholder: {
      type: String,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
      default: "input-id",
    },
  },
  computed: {
    attributes() {
      const attr = { type: "text", id: this.id };
      this.placeholder && (attr["placeholder"] = this.placeholder);
      this.name && (attr["name"] = this.name);
      return attr;
    },
  },
  data() {
    return {
      computedValue: this.value,
    };
  },
  created() {
    if (this.value) {
      this.computedValue = this.formatCurrency(this.value);
    }
  },
  mounted() {
    this.$refs.input.addEventListener("keyup", ({ target: { value } }) => {
      this.$refs.input.value = this.formatCurrency(value);
    });
  },
  methods: {
    formatCurrency(value) {
      value = this.parseCurrency(value);
      let newValue;
      let [whole, fraction] = value.split(".");
      // converting whole to number as toLocaleString works with numbers only
      if (whole !== "") whole = +whole;
      // if user types a . as the first character
      if (whole === "" && fraction === "") {
        whole = 0;
      }
      if (fraction && fraction.length > 2) {
        // fraction can atmost have length 2
        fraction = fraction.substring(0, 2);
      }
      if (fraction) {
        newValue = whole.toLocaleString("en-US") + "." + fraction;
      } else if (fraction === "") {
        // fraction will be empty string when user enters something like 97.
        newValue = whole.toLocaleString("en-US") + ".";
      } else {
        newValue = whole.toLocaleString("en-US");
      }
      if (newValue === "") {
        this.$emit("input", "");
        return "";
      }
      this.$emit("input", this.parseCurrency(newValue));
      return newValue;
    },
    parseCurrency(value) {
      value = value.toString();
      // removing everything except \d and \. (numbers and decimal)
      let parsed = value.replace(/[^\d|.]/g, "");
      // let parsed = value.replace(/[^\d|\.]/g, "");
      return parsed;
    },
  },
};
</script>
