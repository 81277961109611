export default [
  {
    path: "",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/ResetPassword.vue"),
  },
  {
    path: "/invoice/:id",
    name: "Invoice",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Invoice.vue"),
  },
  {
    path: "/receipt/:id",
    name: "Receipt",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Receipt.vue"),
  },
  {
    path: "/track",
    name: "Track",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Track.vue"),
  },
];
